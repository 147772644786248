import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactComponentsProvider } from '@inlight-media/react-components';
import { SessionProvider } from 'next-auth/react';
import PropTypes from 'prop-types';

import { theme } from '../constants/theme';

const queryClient = new QueryClient();

const MyApp = ({ Component, pageProps }) => {
  return (
    // eslint-disable-next-line react/prop-types
    <SessionProvider session={pageProps.session}>
      <QueryClientProvider client={queryClient}>
        <ReactComponentsProvider theme={theme}>
          <Component {...pageProps} />
        </ReactComponentsProvider>
      </QueryClientProvider>
    </SessionProvider>
  );
};

MyApp.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.shape({}).isRequired,
};

export default MyApp;
