import { baseTheme } from '@inlight-media/react-components';

const theme = {
  ...baseTheme,
  fonts: {
    body:
      'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    heading: 'NandosHandAlt',
  },
};

export { theme };
